* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
}

.wapper {
  background-color: whitesmoke;
  min-height: calc(100vh -(56px + 40px));
}

.about-img {
  width: 350px;
  height: 350px;
}