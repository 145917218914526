.logo-symbol{
    width: 40px;
    height: auto;
}

.coin-details-row {
    background-color: rgba(67, 166, 252, 0.658);
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 12px;
}